<template>
  <div class="area">
    <el-cascader
      ref="cascaderAddr"
      v-model="sendAddress"
      :placeholder="data"
      :disabled="show"
      :options="provenceArray"
      @change="selected"
      :props="props"
    ></el-cascader>
  </div>
</template>

<script>
let list = [];
export default {
  props: {
    show: Boolean,
    data: String,
  },
  data() {
    let _self = this;
    return {
      sendAddress: "",
      provenceArray: [],
      props: {
        lazy: true,
        // checkStrictly:true,
        lazyLoad: _self.cascaderLazyload,
      },
      where: {
        type: 1,
        areaCode: 1,
      },
    };
  },
  methods: {
    cascaderLazyload(node, resolve) {
      this.where.type = node.level ? node.level + 1 : 1;
      this.where.areaCode = node.value ? node.value : 1;
      this.$postFormData(this.$api.allAreaList, this.where).then((res) => {
        if (res.code == 1) {
          let cities = (res && res.data && res.data) || [];
          let children = [];
          cities.forEach((item) => {
            const rs = {
              value: item.code,
              label: item.name,
              children: [],
              leaf: node.level >= 2,
            };
            children.push(rs);
            list[item.code] = item.name;
          });
          resolve(children);
        }
      });
    },
    selected(val) {
      if (val && Array.isArray(val) && val.length == 3) {
        let array = [];
        let arr = [];
        val.forEach((item, index) => {
          array[index] = list[item];
          arr[index] = item;
        });
        this.$emit("get-all-area-list", { code: arr, name: array });
      } else {
        this.$message({
          type: "warning",
          message: "区域不完善",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-cascader--small {
  width: 100%;
}
</style>
