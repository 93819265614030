<template>
    <div class="area">
        <el-upload
                class="upload-demo"
                drag
                action=""
                :before-upload="beforeAvatarUpload"
                :http-request="uploadFile"
                :data="param"
                accept="image/*"
                fit="scale-down"
                :show-file-list="false"
                multiple
        >
            <i class="el-icon-upload" v-if="isShow"></i>
            <el-image v-if="path"
                    style="height: 100%"
                    :src="path"
                    fit="contain"></el-image>
            <div class="el-upload__text">{{ title }}<em>点击上传</em></div>
            <!--            <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过2MB</div>-->
        </el-upload>
    </div>
</template>

<script>
    // eslint-disable-next-line no-unused-vars
    import OSS from "ali-oss";

    export default {
        props: {
            title: String,
            param: Object,
            field: String,
            urlPath: String,
        },
        data() {
            return {
                sendAddress: "",
                provenceArray: [],
                actionUploadUrl: "",
                path: "",
                isShow: true,
                token: "",
            };
        },
        watch: {
            contents: {
                handler() {
                    this.token = localStorage.getItem("token");
                },
                immediate: true,
            },
        },
        created() {
            this.client = new OSS(this.$config.aliOss)
        },
        methods: {
            async ossUpload(file) {
                let _this = this
                let url = '';
                let filename = file.name;
                let filenames = filename.split('.')[0] + '_' + new Date().getTime() + '.' + filename.split('.')[1];
                let envPath = process.env.VUE_APP_UPLOAD_PATH;
                let filePath = "appxcx/upload/"+(envPath)+"/" + this.param.fileType + "/";
                let path = filePath + filenames;
                try {
                    var object = await _this.client.multipartUpload(path, file);
                    var img = object.res.requestUrls[0].split("?uploadId");
                    url = img[0];
                } catch (e) {
                    console.log(e)
                }
                return {
                    url:url,
                    data:{
                        fileName: filenames,
                        fileOriName: filename.split('.')[0],
                        filePath: filePath
                    }
                };
            },
            async uploadFile(option) {
                const result = await this.ossUpload(option.file);
                if(!result.url){
                    console.log("return oss url null")
                    return false;
                }
                this.path = result.url;
                this.isShow = false;
                const data = {
                    data: result.data,
                    url: result.url,
                    field: this.field,
                };
                this.$emit("getUploadFile", data);
            },
            beforeAvatarUpload(file) {
                //文件上传之前调用做一些拦截限制
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isLt2M) {
                    this.$message.error("上传图片大小不能超过 2MB! ");
                }
                return isLt2M;
            },
        },
        mounted() {
            setTimeout(() => {
                if (this.urlPath) {
                    this.path = this.urlPath;
                    this.isShow = false;
                    console.log(this.path, "this.path")
                }
            }, 1000)
        },
    };
</script>
<style lang="scss" scoped>
    .el-upload-dragger {
        background-color: transparent;
        border: 1px dashed #d9d9d9;
        position: relative;
        overflow: hidden;
    }

    // .el-upload--picture-card{
    // }
    // .el-upload--picture-card{
    //     /*border: 0px;*/
    // }
    .el-upload-dragger .el-upload__text {
        color: #606266;
        font-size: 14px;
        text-align: center;
        position: absolute;
        top: 0px;
        left: 0px;
        height: 50px;
        /*background-color: #3333;*/
        border-radius: 6px;
        line-height: 50px;
        padding: 101px 40px 29px 40px;
        min-width: 64%;
    }
    /deep/.el-upload-dragger {
      width: 229px;
      height: 180px;
    }
</style>
